import styled from 'styled-components';

export default styled.section`
  background:#080809;
  padding-top: 96px;

  @media screen and (max-width: 375px) {
    padding-top: 0;
  }

  .carditem {
    margin-bottom: 30px;
    border: 2px solid #fc6a2c;
    box-sizing: border-box;
    border-radius: 4px;
    text-align: center;
    padding: 30px;
    min-height: 230px;
    max-width: 370px;
    @media screen and (max-width: 767.98px) {
      display: flex;
      min-height: auto;
      max-width: 100%;
    }
    @media screen and (max-width: 375px) {
      margin-bottom: 18px;
    }
    p {
      padding: 20px 10px;
      font-style: normal;
      font-weight: normal;
      font-size: 22.5px;
      line-height: 28px;
      text-align: center;

      @media screen and (max-width: 375px) {
        text-align: left;
        padding-left: 30px;
      }

      color: #ffffff;
    }
  }
`;
